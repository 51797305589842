import * as React from 'react';
import { DateUtilities } from '../DateUtilities';
import AudioPlayer from 'react-h5-audio-player';
import { withRouter } from "./withRouter";
import LowPriorityIcon from '../assets/svg-dot-grey.svg';
import RegularPriorityIcon from '../assets/svg-dot-blue.svg';
import HighPriorityIcon from '../assets/svg-dot-yellow.svg';
import HighestPriorityIcon from '../assets/svg-dot-red.svg';
import DefaultSongArtwork from '../assets/default-song-image-oluvo-purple.png';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CardMedia from "@mui/material/CardMedia";
import Badge from '@mui/material/Badge';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { SongService } from "../Services/SongService";
import { FileService } from "../Services/FileService";
import {withAuth0} from "@auth0/auth0-react";
import AuthService from "../Services/AuthService";

class SongCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            song: this.props.song,
            isLoadingData: true,
            audioFile: ''
        }
    }

    priorityMap = {
        0: LowPriorityIcon,
        1: RegularPriorityIcon,
        2: HighPriorityIcon,
        3: HighestPriorityIcon
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData(){
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let song = await SongService.findOneById(this.state.song.songTitlePretty, token);

        let songFile = await this.getAudioFile();


        this.setState({
            song: song,
            isLoadingData: false,
            audioFileURL: songFile
        }, this.forceUpdate());
    }

    /**
     * navigate to the details page using the current song identifier as the URL key
     * @param latestVersionFileName
     */
    showSongDetailPage () {
        this.props.router.navigate(`/app/songs/${this.state.song.songTitlePretty}/`);
    }

    getImageForCard() {
        // let image = this.state.song.primaryArtwork ? `${rootUrl}/media/${this.state.song.primaryArtwork.fileName}` : DefaultSongArtwork;

        return DefaultSongArtwork;
    }

    async getAudioFile() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let presignedUrlForSong = await FileService.findOneById(this.state.song.versionHistory[0].fileName, token);
        return presignedUrlForSong.signedURL;
    }


    render() {
        if(!this.state.isLoadingData && this.state.song && this.state.song.versionHistory) {
            let latestVersion = this.state.song.versionHistory[0];
            let latestVersionFilename = latestVersion.fileName;
            // let audioFileLocation = `${rootUrl}/audio/${encodeURIComponent(latestVersionFilename)}`
            let songId = this.state.song.songTitlePretty;
            let hasNotes = latestVersion
                && latestVersion.songVersionNotes
                && latestVersion.songVersionNotes.length > 0
                && latestVersion.songVersionNotes[0];
            let numNotes = hasNotes ? latestVersion.songVersionNotes.length : 0;
            let project = this.state.song.project ? this.state.song.project.name : '-';
            if(!this.state.searchBarText || (this.state.searchBarText && latestVersionFilename.includes(this.state.searchBarText.toLowerCase()))) {
                return (
                    <Card sx={{ minWidth: 325, minHeight: 300, borderRadius: '10px' }} >
                        <CardContent>
                            <CardMedia
                                sx={{
                                    [`@media only screen and (min-width: 100px)`]: {
                                        height: 50,
                                    },
                                    [`@media only screen and (min-width: 700px)`]: {
                                        height: 90,
                                    },
                                    cursor: 'pointer',
                                    borderRadius: '5px'
                                }}
                                image={this.getImageForCard()}
                                title="default song artwork"
                                onClick={() => this.showSongDetailPage(latestVersionFilename)}>
                                <MusicNoteIcon sx={{
                                    [`@media only screen and (min-width: 100px)`]: {
                                        display: "none"
                                    },
                                    [`@media only screen and (min-width: 700px)`]: {
                                        display: "inline-block",
                                        margin: "10px",
                                    }}}/>
                            </CardMedia>
                            <br />
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {project}
                            </Typography>
                            <Typography variant="h5" component="div">
                                    <img className="song-priority-icon" src={this.priorityMap[this.state.song.priority]}></img> {songId}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {DateUtilities.getDateDiffInDays(new Date(latestVersion.createdTime), new Date())} Days Ago
                            </Typography>
                            <Typography variant="body2" sx={{
                                [`@media only screen and (min-width: 300px)`]: {
                                    marginBottom: 0,
                                },
                                [`@media only screen and (min-width: 700px)`]: {
                                    marginBottom: 6,
                                }
                            }}>
                                <AudioPlayer
                                     layout="stacked"
                                     customControlsSection={['MAIN_CONTROLS']}
                                     src={this.state.audioFileURL}
                                     onPlay={e => console.log("onPlay")}
                                 />
                            </Typography>
                        </CardContent>
                    </Card>
                );
            }
        } else {
            return (
                <div>Loading...</div>
            )
        }
    }
}

export default withAuth0(withRouter(SongCard));

/*
    for later:

    ** ADDING CARD ACTIONS **
    e.g. favorite, edit, etc.

    <CardActions>
        <Typography>
            <Badge badgeContent={numNotes}>
                <EditNoteIcon
                    onClick={() => this.showSongDetailPage(latestVersionFilename)}
                    color="action"
                    sx={{cursor: 'pointer'}}
                />
            </Badge>
        </Typography>
        // <FavoriteBorderIcon
        // color="action"
        // sx={{cursor: 'pointer'}}
    </CardActions>
 */