import './App.css';
import { Routes, Route } from "react-router-dom";
import SongsAppContainer from './components/SongsAppContainer';
import SongDetails from './components/SongDetailsList';
import ProjectList from "./components/ProjectList";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ProjectCreate from './components/ProjectCreate';
import MoodboardEdit from './components/MoodboardEdit';
import MoodboardCreate from './components/MoodboardCreate'
import ProjectDetail from './components/ProjectDetail';
import SongEdit from './components/SongEdit';
import SyncFiles from './components/SyncFiles';
import { createTheme } from '@mui/material/styles';
import LandingPage from "./components/LandingPage";
import TempLandingPage from "./components/TempLandingPage";

// const darkTheme = createTheme({
//     palette: {
//         mode: 'dark'
//     },
//     breakpoints: {
//         values: {
//             xs: 0,
//             sm: 1150,
//             md: 1550
//         },
//     }
// });

/**
 * NOTE: CSS BASELINE seems to provide some base styles, though we don't want them yet so we're keeping
 * it around because it may be useful in the future once we understand more about what it is - but for now, keep it out
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
  return (
      <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/app" element={<SongsAppContainer />}></Route>
          <Route path="/app/songs/:songTitlePretty" element={<SongDetails />}></Route>
          <Route path="/app/songs/edit/:songTitlePretty" element={<SongEdit />}></Route>
          <Route path="/app/sync" element={<SyncFiles />}></Route>
          <Route path="/app/project/:id" element={<ProjectDetail />}></Route>
          <Route path="/app/project/new" element={<ProjectCreate />}></Route>
          <Route path="/app/project/list"  element={<ProjectList />}></Route>
          <Route path="/app/moodboard/:moodboardId" element={<MoodboardEdit />}></Route>
          <Route path="/app/moodboard/create" element={<MoodboardCreate />}></Route>

          <Route path="*" element={<div>Page Not Found</div>}
          />
      </Routes>
  );
}

export default App;