import * as React from "react";
import { ProjectService } from "../Services/ProjectService";
import {withRouter} from "./withRouter";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import SongTile from "./SongCard";
import AuthService from "../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";

class ProjectDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id || this.props.router.params.id, // || this.props.router... aka get from the URL
            project: {},
            isLoadingData: true
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let project = await ProjectService.findOneById(this.state.id, token);

        this.setState({
            project: project,
            isLoadingData: false
        });
    }

    getProjectSongsView() {
        this.state.project.songs.map((song) => {
            return (
                <Grid item xs={12} sm={6} md={4}>
                    <SongTile key={song.songTitlePretty} song={song} />
                </Grid>
            )
        });
    }

    render() {
        if(!this.state.isLoadingData) {
            return (
                <div>
                    ID: {this.state.project.id}
                    NAME: {this.state.project.name}

                    <Grid container
                          spacing={4}
                          direction="row">

                        {this.state.project.songs && this.state.project.songs.length > 0 ? this.getProjectSongsView() : <div>No Songs Yet.</div>}
                    </Grid>
                </div>
            )
        } else {
            return (
                <CircularProgress />
            )
        }
    }
}

export default withAuth0(withRouter(ProjectDetail));