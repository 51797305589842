import React from "react";
import { withRouter } from './withRouter';
import LowPriorityIcon from "../assets/svg-dot-grey.svg";
import RegularPriorityIcon from "../assets/svg-dot-blue.svg";
import HighPriorityIcon from "../assets/svg-dot-yellow.svg";
import HighestPriorityIcon from "../assets/svg-dot-red.svg";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import {DateUtilities} from "../DateUtilities";
import {rootUrl} from "../Utilities/Utils";
class SongsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: true,
            songs: {}
        }
    }

    componentDidMount() {
        this.fetchData();
    }
    async fetchData() {
        let dataRaw = await fetch(`${rootUrl}/songs`);
        let data = await dataRaw.json();

        this.setState({
            songs: data,
            isLoadingData: false
        });
    }

    columns = [
        {
            field: 'priority',
            headerName: 'Priority',
            width: 50,
            editable: false,
            renderCell: (params) => {
                let icon;
                switch (params.row.priority) {
                    case 0:
                        icon = LowPriorityIcon;
                        break;
                    case 1:
                        icon = RegularPriorityIcon;
                        break;
                    case 2:
                        icon = HighPriorityIcon;
                        break;
                    case 3:
                        icon = HighestPriorityIcon;
                }

                return (
                    <img className="song-priority-icon" src={icon}></img>
                );
            },
        },
        {
            field: 'songTitlePretty',
            headerName: 'Song Name',
            width: 200,
            editable: false,
        },
        {
            field: 'bpm',
            headerName: 'bpm',
            width: 50,
            editable: false,
        },
        {
            field: 'createdDate',
            headerName: 'Last Updated',
            width: 150,
            editable: false,
            valueGetter: (params) => {
                let numDaysAgo = DateUtilities.getDateDiffInDays(new Date(params.row.versionHistory[0].createdTime), new Date());
                return `${numDaysAgo}  Days Ago`;
            },
        },
        {
            field: 'project',
            headerName: 'Project',
            width: 150,
            editable: false,
            valueGetter: (params) => {
                return params.row.project ? params.row.project.name : '';
            },
        },
        {
            field: 'stage',
            headerName: 'Stage',
            width: 300,
            editable: false,
            valueGetter: (params) => {
                return params.row.stage ? params.row.stage.stageName : '';
            },
        }
    ];

    handleRowClick(params) {
        this.props.router.navigate(`/app/songs/${params.row.songTitlePretty}/`);
    };


    render() {
        if(!this.state.isLoadingData) {
            return (
                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        rows={this.state.songs}
                        columns={this.columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 8,
                                },
                            },
                        }}
                        pageSizeOptions={[8]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowClick={(row) => this.handleRowClick(row)}
                    />
                </Box>
            )
        } else {
            return (
                <CircularProgress />
            )
        }

    }
}

export default withRouter(SongsTable);